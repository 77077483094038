import momentDurationFormatSetup from "moment-duration-format/lib/moment-duration-format";
import moment from "moment/dist/moment";
momentDurationFormatSetup(moment);

var refreshTime = 125; // must be < then pollTime
var pollTime = 250;

var currentData = {};
var setupCountdowns = function() {
  var countdownInterval = setInterval(updateTime, refreshTime, currentData);
}

var updateTime = function (data) {

  if(!data.current_end_at) return;
  
  var now = moment().valueOf();
  var currentEndAt = moment(data.current_end_at);

  // Find the distance
  var clientDistance = currentEndAt - now + pollTime; // fix the distance adding the pollTime 
  var serverDistance = data.time_remaining * 1000;

  if(data.in_extra_time) {
    if(clientDistance > 10000) clientDistance = 10000;
  }
  if(clientDistance < 0) clientDistance = 0;

  var clientTimeRemainingDuration = moment.duration(clientDistance, "milliseconds");
  var extraTimeRemainingDuration = moment.duration(data.extra_time_remaining, "seconds");
  var maxExtraTimeRemainingDuration = moment.duration(data.max_extra_time_minutes, "minutes");

  updateExtraTime(data.in_extra_time, extraTimeRemainingDuration);
  updateMaxExtraTimeMinutes(data.in_extra_time, extraTimeRemainingDuration, maxExtraTimeRemainingDuration);
  updateEnd(clientTimeRemainingDuration, data.extra_time_extension_seconds, data.in_extra_time);
  updateConnectionQuality(data.in_extra_time, clientDistance, serverDistance);
}
var updateExtraTime = function(in_extra_time, extra_time_remaining_duration) {
  if (in_extra_time) {
    $("#live-auction").addClass("live-auction__extra-time");
    $("#live-auction-detail").addClass("live-auction-detail__extra-time");
    var extraTimeRemaining = extra_time_remaining_duration.format("hh:mm:ss", { trim: false });
    $("#extra-time-remaining").text(extraTimeRemaining);
  }
};
var updateMaxExtraTimeMinutes = function(in_extra_time, extra_time_remaining_duration, max_extra_time_minutes_duration) {
  if (in_extra_time) {
    $("#live-auction").addClass("live-auction__extra-time");
    var extraTimeRemaining = extra_time_remaining_duration.format("hh:mm:ss", { trim: false });
    $("#extra-time-remaining").text(extraTimeRemaining);

    $("#banner-time-remeaning-value").text(extraTimeRemaining);
    $("#banner-end-time").addClass("auction-detail__banner-end");
    $("#banner-time-remeaning").removeClass("auction-detail__banner-remeaning");
  } else {
    var maxExtraTimeRemaining = max_extra_time_minutes_duration.format("hh:mm:ss", { trim: false });
    $("#extra-time-remaining").text(maxExtraTimeRemaining);

    $("#banner-end-time").removeClass("auction-detail__banner-end");
    $("#banner-time-remeaning").addClass("auction-detail__banner-remeaning");
  }
};
var updateEnd = function(time_remaining_duration, extra_time_extension_seconds, in_extra_time) {
  // update end at
  var timeRemainingComponents = time_remaining_duration.format("dd:hh:mm:ss", { trim: false }).split(":");

  $("#time-remaining-days").text(timeRemainingComponents[0] + " : ");
  $("#time-remaining-hours").text(timeRemainingComponents[1] + " : ");
  $("#time-remaining-minutes").text(timeRemainingComponents[2] + " : ");
  $("#time-remaining-seconds").text(timeRemainingComponents[3]);

  if (in_extra_time) {
    $("#extra-time-progress-bar").parent().show();
    $("#full-progress-bar").hide();
    var progress_percentage = ((time_remaining_duration.seconds() * 1000) + time_remaining_duration.milliseconds()) / (extra_time_extension_seconds * 1000);
    $("#extra-time-progress-bar").css("width", progress_percentage * 100 + "%");
  }
  else {
    $("#extra-time-progress-bar").parent().hide();
    $("#full-progress-bar").show();
  }

};
var distanceQualityHistory = [];
var updateConnectionQuality = function(in_extra_time, client_distance, server_distance) {

  var connectionQuality = 'disabled';

  if(in_extra_time) {

    if(distanceQualityHistory.length > 5) distanceQualityHistory.shift();
    distanceQualityHistory.push((server_distance - client_distance) / pollTime);
    var avgDistanceQuality = distanceQualityHistory.reduce(function(a, b) { return a + b; }, 0) / distanceQualityHistory.length;
    
    switch (true) {
      case (avgDistanceQuality < 1):
        connectionQuality = 5;
        break;
      case (avgDistanceQuality < 2):
        connectionQuality = 4;
        break;
      case (avgDistanceQuality < 3):
        connectionQuality = 3;
        break;
      case (avgDistanceQuality < 4):
        connectionQuality = 2;
        break;
      case (avgDistanceQuality < 5):
        connectionQuality = 1;
        break;
      default:
        connectionQuality = 0;
        break;
    }

  }

  $('.live-auction__slow-connection-quality').removeClass().addClass('live-auction__slow-connection-quality live-auction__slow-connection-quality__' + connectionQuality);
}

var updatePrice = function (data) {
  // update auction price
  $("#current_price").text(data.current_price);
   $("#current_price_large_screen").text(data.current_price);
};
var updateWinner = function (data) {
  // update winner
  $("#current_winner").text(data.current_winner.username);
};
var setupBidButton = function () {
  if (!document.getElementById("auction-show")) return;

  var $auctionPage = $("#auction-show");
  var $bidButton = $("#bid_button");

  $bidButton.prop("disabled", true);

  $bidButton.on("click", function (eventObj) {
    $.ajax({
      headers: {
        Authorization: "Bearer " + $bidButton.data("jwt"),
      },
      url: "https://9yxm7kac4b.execute-api.eu-west-1.amazonaws.com/api/bids/" + $auctionPage.data("auction-id"),
      success: function (data) {
        // console.log(data);
      },
      error: function (error) {
        console.log(error);
      },
    });
  });
};

function truncate(str, maxLength) {
  if (str.length > maxLength) {
    return str.substr(0, maxLength - 1) + "…";
  } else {
    return str;
  }
}

var updateList = function (data) {
  // update bid list
  var rows = data.bids.map(function (bid, index) {
    if (data.bids.length == 1) {
      //can't have truncate due to other js-> default message of starting auction
      var row = $('<tr class="auction-show__last-bidders-table__current_winner"></tr>')
      row.append('<td class="bidder-number"><h4></h4></td>');
      row.append('<td class="bidder-name"><h6 class="first-row center-align auction-started-text">' + bid.user.username + '</td>');
    } 
    else {
      if (index == 0) {
        var row = $('<tr class="auction-show__last-bidders-table__current_winner"></tr>')
        row.append('<td class="bidder-number"><h4>' + (index + 1) + '</h4></td>');
        row.append('<td class="bidder-name"><div class="valign-wrapper"><h6 class="first-row">' + bid.user.username + '</h6>' + '<h6 class="second-row"><small>' + moment(bid.created_at).format("DD/MM/YY") + ' ' + moment(bid.created_at).format("HH:mm:ss") + '</small></h6></div><i class="fas fa-trophy"></i></td>')
        } 
      else {
        var row = $("<tr></tr>")
        row.append('<td class="bidder-number"><h4>' + (index + 1) + '</h4></td>');
        row.append('<td class="bidder-name"><div class="valign-wrapper"><h6 class="first-row">' + bid.user.username + '</h6>' + '<h6 class="second-row"><small>' + moment(bid.created_at).format("DD/MM/YY") + ' ' + moment(bid.created_at).format("HH:mm:ss") + '</small></h6></div></td>')
      };
    };
    return row;
  });

  $("tbody", "#auction_bid_list").html(rows);
};
var updateTokens = function (data) {
  // update tokens
  if (data.current_user) {
    // toekns in nav bar
    $("#token-number").text(data.current_user.tokens);
    // tokens in bid button
    $("#button-token-number").text(data.current_user.tokens);
    if (data.current_user.tokens > 0) {
      $("#hint").addClass("live-auction-detail__token-present");
      $("#hint__responsive").addClass("live-auction-detail__token-present");
      $("#bid_button").addClass("live-auction-detail__place-bid-button__token-present");
      $("#bid_button").removeClass("live-auction-detail__place-bid-button__token-not-present");
    }
  }
};
var updateButton = function (data) {
  // disable bid button if
  // - current user is current winner
  // - current user has 0 tokens
  // - current user cannot partecipate extra time if he/she not bid before extra time
  // - auction expired (auction ended or maximum extra time reached)
  $("#bid_button").prop(
    "disabled",
    data.current_winner.is_current_user ||
      data.current_user?.tokens < 1 ||
      (data.in_extra_time && !data.current_user?.can_extra_time)
  );
  if (data.time_remaining === 0) {
    $("#bid_button").attr("disabled", "disabled");
    $("#bid_button").addClass("live-auction-detail__place-bid-button__expired ");   
    $("#text-0").show();
    $("#text-1").hide();
    $("#text-3").hide();
    $("#text-4").hide();
    $("#text-2").hide();
  }

  if (data.in_extra_time) {
    if (!data.current_user?.can_extra_time) {
      $("#text-0").hide();
      $("#text-1").hide();
      $("#text-3").hide();
      $("#text-4").hide();
      $("#text-2").show();
    }
    if (data.current_user?.can_extra_time && !data.current_winner.is_current_user) {
      $("#text-0").hide();
      $("#text-2").hide();
      $("#text-3").hide();
      $("#text-4").hide();
      $("#text-1").show();
    }
    if (data.current_winner.is_current_user) {
      $("#text-0").hide();
      $("#text-1").hide();
      $("#text-2").hide();
      $("#text-3").hide();
      $("#text-4").show();
    }
  }

  if (!data.in_extra_time) {
    if (data.current_winner.is_current_user) {
      $("#text-1").hide();
      $("#text-2").hide();
      $("#text-3").hide();
      $("#text-4").show();
    }
    else {
      if (data.current_user?.can_extra_time) {
        $("#text-2").hide();
        $("#text-3").hide();
        $("#text-4").hide();
        $("#text-1").show();
      }
      else {
        $("#text-1").hide();
        $("#text-2").hide();
        $("#text-4").hide();
        $("#text-3").show();
      }
    }
  }

};
var updateModals = function (data) {
  // update auction price
  $("#final-modal__current_price").text(data.current_price);
  
  // update winner
  $("#final-modal__current_winner").text(data.current_winner.username);
};

var setupRefresh = function () {
  if (!document.getElementById("auction-show")) return;

  var $auctionPage = $("#auction-show");

  function pollAuction(successCallback) {
    $.ajax({
      url:
        window.location.protocol +
        "//" +
        window.location.hostname +
        ":" +
        window.location.port +
        "/auctions/" +
        $auctionPage.data("auction-id") +
        ".json?locale=" +
        currentLocale,
      success: successCallback,
      error: function (error) {
        // try reschedule polling
        setTimeout(refreshAuction, pollTime);

        // log error to console
        console.log(error);
      },
    });
  }

  function refreshAuction() {
    var callback = function (data) {
      /* LIVE AUCTION */

      // Update global variables
      currentData.in_extra_time = data.in_extra_time;
      currentData.time_remaining = data.time_remaining;
      currentData.current_end_at = data.current_end_at;
      currentData.extra_time_remaining = data.extra_time_remaining;
      currentData.max_extra_time_minutes = data.max_extra_time_minutes;
      currentData.extra_time_extension_seconds = data.extra_time_extension_seconds;


      setTimeout(updatePrice, 0, data);
      setTimeout(updateWinner, 0, data);
      setTimeout(updateList, 0, data);
      setTimeout(updateTokens, 0, data);
      setTimeout(updateButton, 0, data);

      /* WINNER/LOSER MODALS */
      setTimeout(updateModals, 0, data);

      if(data.expired) {
        $("#auction-show-final-modal").modal({ dismissible: false });
        $("#auction-show-final-modal").modal("open");

        setTimeout(function() {
          pollAuction(function(data) {
          
            setTimeout(setupCountdowns, 0, data);
            setTimeout(updatePrice, 0, data);
            setTimeout(updateWinner, 0, data);
            setTimeout(updateList, 0, data);
            setTimeout(updateTokens, 0, data);
            setTimeout(updateButton, 0, data);

            /* WINNER/LOSER MODALS */
            setTimeout(updateModals, 0, data);
            
            if (data.current_winner.is_current_user ){
              $("#auction-show-final-modal").addClass("final-modal__modal__winner");
            }
            else {
              $("#auction-show-final-modal").addClass("final-modal__modal__loser");
            }

            // Work-around for problem that modal do not remove scrolling stop from body (look here: https://github.com/Dogfalo/materialize/issues/4622 )
            $("#auction-show-final-modal").modal({ dismissible: true, onCloseEnd: function() { $('body').css({ overflow: 'visible' }); } });
            $("#auction-show-final-modal").modal("open");
          })
        }, 5000)
      }
      else {
        setTimeout(refreshAuction, pollTime);
      }
    };

    pollAuction(callback);
  }

  // start polling
  refreshAuction();
  setupCountdowns();
};

window.addEventListener("DOMContentLoaded", setupRefresh);
window.addEventListener("DOMContentLoaded", setupBidButton);