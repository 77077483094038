import momentDurationFormatSetup from "moment-duration-format/lib/moment-duration-format";
import moment from "moment/dist/moment";

// Initialize countdown for auctions
var initAuctionCountdown = function() {
  momentDurationFormatSetup(moment);
  var elems = document.querySelectorAll(".countdown");

  elems.forEach(function (elem) {
    var countDownDate = moment(elem.getAttribute("value"), 'YYYY-MM-DD hh:mm:ss Z').valueOf();
    //  Update the count down every 1 second
    var x = setInterval(function () {
      // Get today's date and time
      var now = moment().valueOf();

      // Find the distance between now and the count down date
      var distance = countDownDate - now;
      if(distance <= 0) {
        distance = 0;
      }

      // Time calculations for days, hours, minutes and seconds
      var timeRemaining = moment.duration(distance, "milliseconds").format("dd : hh : mm : ss", { trim: false })

      // Display the result in the element
      elem.innerHTML = timeRemaining;

      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(x);
      }
    }, 1000);
  });
};

document.addEventListener('DOMContentLoaded', initAuctionCountdown);
document.addEventListener('turbolinks:load', initAuctionCountdown);