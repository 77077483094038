// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import '@fortawesome/fontawesome-free/css/all.css';
import * as ActiveStorage from '@rails/activestorage';
import Rails from '@rails/ujs';
import 'channels';
import 'materialize-css/dist/js/materialize';
// import Turbolinks from "turbolinks";
import '../stylesheets/application';

import './application/auction';
import './application/auctions';
import './application/change_info';
import './application/cms_popups';
import './application/google_tag_handlers';
import './application/init_carousel_materialize';
import './application/init_clipboard';
import './application/init_collapsible_materialize';
import './application/init_cookie_eu_banner';
import './application/init_countdown';
import './application/init_dropdown_materialize';
import './application/init_materialbox_materialize';
import './application/init_modal_materialize';
import './application/init_select_materialize';
import './application/init_sidenav_materialize';
import './application/init_splide';
import './application/init_tabs_materialize';
import './application/init_time_zone';
import './application/live_auction_items';
import './application/order_processing';
import './application/paypal_payment';
import './application/stripe_payment';
import './application/zen_payment';
import './application/password_icon';

// Must be after init modal to work
import './application/auction_popup_reminders';

Rails.start();
// Turbolinks.start();
ActiveStorage.start();
