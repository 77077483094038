/* Initialize popups modal */

import moment from 'moment/dist/moment';

var initAuctionPopupReminderModal = function () {
  openBeforeAuctionEnd();
  setInterval(openBeforeAuctionEnd, 10000);
};

/* Popup Open functions */

var openAuctionPopupReminder = function (auction_popup_reminder_modal_id) {
  var elem = document.getElementById(auction_popup_reminder_modal_id);

  if (elem != undefined) {
    var instance = M.Modal.getInstance(elem);
    instance.open();
    sessionStorage.setItem(auction_popup_reminder_modal_id, true); // Use session storage to show reminder just once for every tab/browser
  }
};

var openBeforeAuctionEnd = function () {
  document
    .querySelectorAll('.auction-popup-reminder-modal')
    .forEach(function (currentValue, currentIndex, listObj) {
      var now = moment();
      var auctionEndAt = moment(
        currentValue.dataset.auctionEndAt,
        'YYYY-MM-DD HH:mm:ss'
      );
      if (now < auctionEndAt) {
        var deadline = auctionEndAt.subtract(
          currentValue.dataset.minutesBeforeEnd,
          'minutes'
        );
        var alreadyOpened = sessionStorage.getItem(currentValue.id) != null;
        if (now > deadline && !alreadyOpened) {
          openAuctionPopupReminder(currentValue.id);
        }
      }
    });
};

/* Events */

document.addEventListener('DOMContentLoaded', initAuctionPopupReminderModal);
document.addEventListener('turbolinks:load', initAuctionPopupReminderModal);
