import momentDurationFormatSetup from "moment-duration-format/lib/moment-duration-format";
import moment from "moment/dist/moment";
momentDurationFormatSetup(moment);

const initLiveAuctionItems = () => {

  const $liveAuctionItems = $('.live-auction-item');
  // const liveAuctionIds = $liveAuctionItems.map(function() {
  //     const $element = $(this);
  //     return $element.data('auctionId');
  // }).get();

  function refreshAuction(liveAuctionItemElement) {

    const auctionId = $(liveAuctionItemElement).data('auctionId');

    $.ajax({
      url:
        window.location.protocol +
        "//" +
        window.location.hostname +
        ":" +
        window.location.port +
        "/auctions/" +
        auctionId +
        ".json?locale=" +
        currentLocale,
      success: function (data) {

        // update winner
        const $gridAuctionsItemCurrentWinner = $(liveAuctionItemElement).find('.live-auction-item-current-winner');
        $gridAuctionsItemCurrentWinner.text(data.current_winner.username);

        // update auction price
        const $gridAuctionsItemCurrentPrice = $(liveAuctionItemElement).find('.live-auction-item-current-price');
        $gridAuctionsItemCurrentPrice.text(data.current_price);

        // update countdown
        // const $gridAuctionsItemTimeRemaining = $(liveAuctionItemElement).find('.live-auction-item-time-remaining');
        // const timeRemaining = moment.duration(data.time_remaining, "seconds").format("dd : hh : mm : ss", { trim: false });
        // $gridAuctionsItemTimeRemaining.text(timeRemaining);

        if (data.in_extra_time) {
          $(liveAuctionItemElement).addClass("extra-time");
          const $gridAuctionsItemTimeRemaining = $(liveAuctionItemElement).find('.live-auction-item-time-remaining > h6');
          $gridAuctionsItemTimeRemaining.text('Extra Time');
        }

        if (data.expired) {
          location.reload();
        }
        else {
          setTimeout(refreshAuction, 3000, liveAuctionItemElement);
        }
      },
      error: function (error) {
        // try reschedule polling
        setTimeout(refreshAuction, 3000, liveAuctionItemElement);

        // log error to console
        console.log(error)
      },
    });

  }

  // start polling
  $liveAuctionItems.each(function () {
    refreshAuction(this);
  });
}

window.addEventListener("DOMContentLoaded", initLiveAuctionItems);