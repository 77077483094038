const togglePasswordIcon = () => {
    $(".eye_icon").on('click',function() {
        $(".eye_icon").toggleClass('fa-eye');
        $(".eye_icon").toggleClass('fa-eye-slash');
        if ($(".password").attr('type') === 'password') {
            $(".password").attr('type', 'text');
        } else {
            $(".password").attr('type', 'password');
        }
    });
}

document.addEventListener('DOMContentLoaded', togglePasswordIcon);