function initStripeForm() {
  if (!document.getElementById("stripe_payment")) return;

  var stripe = Stripe('pk_live_51IIbF9BNWghQcAs4aN7WtEcpQ6ngBVUBevAiOQyk7C74erFLSHmKmYsA1lolOvyC3j9NFb07OI7Oukss3T8s9pFD00ULZMOdAg');
  var elements = stripe.elements({ locale: currentLocale });

  // Custom styling can be passed to options when creating an Element.
  var style = {
    base: {
      // Add your base input styles here. For example:
      fontFamily: '"Libre Franklin", sans-serif',
      fontSize: '16px',
      lineHeight: '24px'
    }
  };

  // Create an instance of the card Element.
  var card = elements.create('card', { iconStyle: 'solid', style: style });

  // Add an instance of the card Element into the `card-element` <div>.
  card.mount('#stripe_order_card-element');

  // Create a token or display an error when the form is submitted.
  var form = document.getElementById('new_stripe_order');

  form.addEventListener('submit', async (event) => {
    event.preventDefault();
    var redirectUrl = document.getElementById("stripe_payment").dataset.redirectUrl;

    const response = await fetch('/orders.json', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        order: { 
          payment_gateway: 'stripe',
          orderable_id: $('#order_orderable_id', form).val() ,
          orderable_type: $('#order_orderable_type', form).val()
        },
        redirect_url: redirectUrl
      })
    });
    const order = await response.json();

    try {
      
      stripe.confirmCardPayment(order.payment_ref, {
        payment_method: {
          card: card
        }
      }).then(function (result) {
          if (result.error) {
            // Inform the customer that there was an error.
            // console.log(result.error)
            $('#stripe_order_card-error').text(result.error.message);

            // Re-enable submit button
            const submitButton = document.getElementById("stripe_order_submit");
            $.rails.enableElement(submitButton);
          }
          else {
            // Redirect to processing page waiting for webhook
            window.location.replace("/orders/" + order.id + "/processing"+"?redirect_to=" + redirectUrl);
          }
      });

    } catch (error) {
      $('#stripe_order_card-error').text(order.payment_data.errors[0] || order.errors[0] || 'Error');

      // Re-enable submit button
      const submitButton = document.getElementById("stripe_order_submit");
      $.rails.enableElement(submitButton);
    }

  });
}

document.addEventListener('DOMContentLoaded', initStripeForm);