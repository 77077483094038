import Cookies from 'js-cookie';
import moment from "moment/dist/moment";

function set_time_zone_offset() {
    const now = moment();
    let utcOffset = now.utcOffset();
    if(now.isDST()) utcOffset = utcOffset - 60;
    Cookies.set('browser_time_zone', utcOffset);
}

set_time_zone_offset();