function initZenForm() {
  if (!document.getElementById('zen_payment')) return;

  var zen_payment_element = $("#zen_payment");
  
  // hide/show payment selection accordion menu
  if($('select[name="product_variant"]')) {
    if($('select[name="product_variant"]').val() == "") {
      zen_payment_element.hide();
    }

    $('select[name="product_variant"]').on('change', function(event) {
      if (event.target.value) {
        zen_payment_element.fadeIn();
      } else {
        zen_payment_element.fadeOut();
      }
    });
  }

  // Create a token or display an error when the form is submitted.
  var form = document.getElementById('zen_payment_form');

  form.addEventListener('submit', async (event) => {
    event.preventDefault();

    // If variants are present then you must select one
    if($('select[name="product_variant"]') && $('select[name="product_variant"]').val() == "") {
      return;
    }

    var orderBody = { 
      payment_gateway: 'zen',
      orderable_id: $('#zen-button-container').data('orderable-id'),
      orderable_type: $('#zen-button-container').data('orderable-type'),
      payment_ref: $('#merchantTransactionId').val(),
    }

    if($('select[name="product_variant"]')) {
      orderBody['product_variant'] = $('select[name="product_variant"]').val();
    }

    const createOrderResponse = await fetch('/orders.json', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        order: orderBody
      }),
    });
    const order = await createOrderResponse.json();

    if(order.id){
      const getSignatureResponse = await fetch(`/orders/${order.id}/zen_signature.json?` + new URLSearchParams({
          mti: $('#merchantTransactionId').val(),
        }), {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      });
      const signatureData = await getSignatureResponse.json();

      // console.log(signatureData);

      const base_url = $('#customIpnUrl').val();
      $('#customIpnUrl').val(`${base_url}/orders/${order.id}/zen_processing`);
      $('#signature').val(signatureData.signature);
      $('#urlReturn').val(signatureData.url_return);
      
      form.submit();
    }
  });
}

document.addEventListener('DOMContentLoaded', initZenForm);
