// Initialize carousel component
var initTabs = function () {
  var elems = document.querySelectorAll(".tabs");
  M.Tabs.init(elems, {
    onShow: function() { 
      window.dispatchEvent(new Event('resize')); 
    }
  });
};

$(document).ready(function () {
  $("#tabEvidence").click(function () {
    document.getElementById("tabEvidence").style.display = "none";
    document.getElementById("tabGrid").style.display = "block";
  });
  $("#btnGrid").click(function () {
    document.getElementById("tabGrid").style.display = "none";
    document.getElementById("tabEvidence").style.display = "block";
  });
});

document.addEventListener("DOMContentLoaded", initTabs);
document.addEventListener("turbolinks:load", initTabs);
