
function handleTagPayWithPaypal() {

    const selectedPackage = $("#paypal_payment").data()
    // console.log(selectedPackage)
    dataLayer.push({
        "event":"checkout",
        "ecommerce":{
            "checkout":{
            "actionField":{
                "step":2
            },
            "products":[
                {
                    "name": selectedPackage.orderableName,
                    "id": selectedPackage.orderableId,
                    "price": selectedPackage.orderablePrice,
                    "quantity": "1"
                }
            ]
            }
        }
    });
}

function handleTagPayWithStripe() {

    const selectedPackage = $("#stripe_payment").data()
    // console.log(selectedPackage)
    dataLayer.push({
        "event":"checkout",
        "ecommerce":{
            "checkout":{
            "actionField":{
                "step":2
            },
            "products":[
                {
                    "name": selectedPackage.orderableName,
                    "id": selectedPackage.orderableId,
                    "price": selectedPackage.orderablePrice,
                    "quantity": "1"
                }
            ]
            }
        }
    });
}

function handleTagPayNow() {

if (!document.getElementById("order_processing")) return;

    const order = $("#order_processing").data()
    // console.log(order)
    
    dataLayer.push({
        "event":"checkout",
        "ecommerce":{
            "checkout":{
            "actionField":{
                "step":3
            },
            "products":[
                {
                    "name": order.orderName,
                    "id": order.orderId,
                    "price": order.orderPrice,
                    "quantity": "1"
                }
            ]
            }
        }
    });
}

function handlePaymentResponse() {

    if (!document.getElementById("buy_token")) return;
    
    const order = $("#buy_token").data();

    if (Object.entries(order).length != 0) {

        // Only send CreditTokenPackage purchases 
        if(order.orderType == "CreditTokenPackage") {

            dataLayer.push({
                "event":"purchase",
                "ecommerce": {
                    "currencyCode": order.orderPriceCurrency,
                    "purchase": {
                        "actionField":{
                            "id": order.orderId,
                            "affiliation": order.orderPaymentGateway,
                            "revenue": order.orderPrice,
                            "tax": "",
                        },
                        "products":[
                            {
                            "name": order.orderName,
                            "id": order.orderId,
                            "price": order.orderPrice,
                            "quantity": "1"
                            }
                        ]
                    }
                }
            });
        }
    }
}

function handleRegistrationSuccess() {
    if (!document.getElementById("registration-welcome")) return;
    
    const user = $("#registration-welcome").data();

    if (Object.entries(user).length != 0) {

        dataLayer.push({
            "event":"GAevent",
            "eventCategory": "user",
            "eventAction": "register",
            "eventLabel": user.userType + "-" + user.userId
        });
    }
}

$(document).on("click","#paypal_payment", handleTagPayWithPaypal);
$(document).on("click","#stripe_payment", handleTagPayWithStripe);
$(window).on("load", handleTagPayNow);
$(window).on("load", handlePaymentResponse);
$(window).on("load", handleRegistrationSuccess);