// Initialize splide component
import Splide from "@splidejs/splide";

var initSplide = function () {
  /* Generic Sliders */
  var elems = document.querySelectorAll(".carousel-splide");

  elems.forEach(function (e) {
    new Splide(e, {
      width: "95vw",
      padding: { right: "96px" },
      perPage: 4,
      breakpoints: {
        600: { perPage: 1, padding: 0 },
        960: { perPage: 2, padding: { right: "32px" } },
        1024: { perPage: 3, padding: { right: "64px" } }
      },
    }).mount();
  });

  /* Home Slider */
  var elems = document.querySelectorAll(".home-carousel-splide");

  elems.forEach(function (e) {
    var homeSplide = new Splide(e, {
      type: "loop",
      width: "95vw",
      perPage: 1,
      arrows: false,
      autoplay: true,
      padding: 2,
      breakpoints: {
        600: { width: "80vw" },
      },
    }).mount();

  });
};

document.addEventListener("DOMContentLoaded", initSplide);
document.addEventListener("turbolinks:load", initSplide);
