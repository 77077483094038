function initPaypalForm() {

  // Initialize all paypal instances if any
  $('.paypal-payment').each(function(index, element) {

    var paypal_payment_element = $(element);
    var paypal_button_container_element = $('.paypal-button-container', element); 
    
    // hide/show payment selection accordion menu
    if($('select[name="product_variant"]')) {
      if($('select[name="product_variant"]').val() == "") {
        paypal_payment_element.hide();
      }

      $('select[name="product_variant"]').on('change', function(event) {
        if (event.target.value) {
          paypal_payment_element.fadeIn();
        } else {
          paypal_payment_element.fadeOut();
        }
      });
    } 

    var order = null;

    const updateOrder = async(actions = null) => {
    
      var redirectUrl = paypal_payment_element.data('redirectUrl');

      const response = await fetch('/orders/' + order.id + '.json', {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ order: {}})
      });
      order = await response.json();

      // Handle UNPROCESSABLE_ENTITY error
      const declinedError = (order.payment_data?.errors || []).find(function (error) {
        return error.table.details.find(function (detail) {
          return detail.table.issue === 'INSTRUMENT_DECLINED';
        });
      });

      if((actions && actions.restart) && declinedError) {
        return actions.restart();
      }
      else {
        // Redirect to processing page
        window.location.replace("/orders/" + order.id + "/processing"+"?redirect_to=" + redirectUrl);
      }
    }

    var FUNDING_SOURCES = [
      paypal.FUNDING.PAYPAL,
      // paypal.FUNDING.CREDIT,
      paypal.FUNDING.CARD
    ];

    FUNDING_SOURCES.forEach(function(fundingSource) {

      paypal.Buttons({
        style: {
          label: 'pay'
        },
        env: 'live', // Valid values are sandbox and live.
        fundingSource: fundingSource,
        createOrder: async () => {

          // If variants are present then you must select one
          if($('select[name="product_variant"]') && $('select[name="product_variant"]').val() == "") {
            return;
          }

          var orderBody = { 
            payment_gateway: 'paypal',
            orderable_id: paypal_button_container_element.data('orderable-id') ,
            orderable_type: paypal_button_container_element.data('orderable-type')
          }

          if($('select[name="product_variant"]')) {
            orderBody['product_variant'] = $('select[name="product_variant"]').val();
          }

          // console.log('Called Create Order');
          const response = await fetch('/orders.json', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
              order: orderBody
            }),
            experience: {
              input_fields: {
                no_shipping: 1
              }
            }
          });
          order = await response.json();
          return order.payment_ref;
        },
        onApprove: async (data, actions) => {
          // console.log('Called On Approve');
          updateOrder(actions);
        },
        onCancel: async (data, actions) => {
          // console.log('Called On Cancel');
          updateOrder(actions);
        },
        onError: async (err) => {
          // console.log('Called On Error');
          updateOrder();
        }
      }).render('#' + paypal_button_container_element.get(0).id);   
    });

  });
}

document.addEventListener('DOMContentLoaded', initPaypalForm);