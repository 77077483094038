const changeInfo = () => {
  if(document.getElementById('profile_same_shipping_information').checked) {
    $('#change_info__contact_details').hide(); // Start with shipping form as hidden
  }

  $('#profile_same_shipping_information').change(function () {
    if (this.checked) {
      $('#change_info__contact_details').hide();
    } else {
      $('#change_info__contact_details').show();
    }
  });
};

document.addEventListener('DOMContentLoaded', changeInfo);
document.addEventListener('turbolinks:load', changeInfo);
