/* Initialize popups modal */

var initPopupModal = function () {
    openByAfterOnPageForSeconds();
    openShowOnExitPage();
    openByShowAfterPageScrollPercents();
};

/* Popup Open functions */

var openPopup = function() {
    var elem = document.querySelectorAll('.popup-modal')[0];
    
    var cmsPopupId = getCmsPopupId();

    $('[class$=__link]', elem).on('click', function(e) {
        updateClickCount(cmsPopupId);
        return true;
    });

    if(elem != undefined) {
        var instance = M.Modal.getInstance(elem);
        instance.open();
        updateViewCount(cmsPopupId);
    }
}

var openByAfterOnPageForSeconds = function() {
    var afterOnPageForSeconds = getAfterOnPageForSeconds();
    
    if(afterOnPageForSeconds == 0) return;

    // start timeout and open the popup
    setTimeout(function() {
        openPopup();
    }, afterOnPageForSeconds);
}

var openShowOnExitPage = function() {
    var showOnExitPage = getShowOnExitPage();

    if(!showOnExitPage) return;

    // var handleBeforeUnload = function (e) {
    //     // Remove the listener
    //     window.removeEventListener('beforeunload', handleBeforeUnload);

    //     openPopup();

    //     // Cancel the event
    //     e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
    //     // Chrome requires returnValue to be set
    //     e.returnValue = '';
    // }

    // window.addEventListener('beforeunload', handleBeforeUnload);

    var shown = false;
    var mainElement = document.getElementsByTagName("main")[0]

    var handleMouseEnter = function() {
        mainElement.addEventListener('mouseleave', handleMouseLeave);
    }

    var handleMouseLeave = function() {
        // Remove the listener
        if(!shown) {
            mainElement.removeEventListener('mouseleave', handleMouseLeave);
            openPopup();
            shown = true;
        }
    }

    mainElement.addEventListener('mouseenter', handleMouseEnter);
}

var openByShowAfterPageScrollPercents = function() {
    var showAfterPageScrollPercent = getShowAfterPageScrollPercent();

    if(showAfterPageScrollPercent == 0) return;

    var handleOnScroll = function(event) { 

        var h = document.documentElement, 
            b = document.body,
            st = 'scrollTop',
            sh = 'scrollHeight';

        var percentScroll = (h[st]||b[st]) / ((h[sh]||b[sh]) - h.clientHeight) * 100;

        if(percentScroll > showAfterPageScrollPercent) {
            window.removeEventListener('scroll', handleOnScroll);
            openPopup();
        }
    } 
    window.addEventListener('scroll', handleOnScroll) ;
}

/* Popup data props getters */

var getCmsPopupId = function() {
    var elem = document.querySelectorAll('.popup-modal')[0];
    return elem.dataset.cmsPopupId;
}

var getAfterOnPageForSeconds = function() {
    var elem = document.querySelectorAll('.popup-modal')[0];

    var afterOnPageForSeconds = 0;

    try {
        afterOnPageForSeconds = parseInt(elem.dataset.afterOnPageForSeconds) * 1000;
    } catch (error) {
        console.log(error); // Something wrong log the error
    }

    return afterOnPageForSeconds;
}

var getShowOnExitPage = function() {
    var elem = document.querySelectorAll('.popup-modal')[0];
    if(elem) {
        return elem.dataset.showOnExitPage == "true";
    }
    else {
        return false;
    }
}

var getShowAfterPageScrollPercent = function() {
    var elem = document.querySelectorAll('.popup-modal')[0];
    var showAfterPageScrollPercent = 0;

    try {
        showAfterPageScrollPercent = parseInt(elem.dataset.showAfterPageScrollPercent);
    } catch (error) {
        console.log(error); // Something wrong log the error
    }

    return showAfterPageScrollPercent;
}

/* Popup ajax update calls */

var updateViewCount = function(cmsPopupId) {
    $.ajax({
        url:
          window.location.protocol +
          "//" +
          window.location.hostname +
          ":" +
          window.location.port +
          "/cms_popups/" +
          cmsPopupId +
          "/user_cms_popups/add_view_count.json",
        type : 'PATCH',
        success: function(data) {
            // do nothing if success
        },
        error: function (error) {
            // log error to console
            console.log(error);
        },
    });
}

var updateClickCount = function(cmsPopupId) {
    $.ajax({
        url:
          window.location.protocol +
          "//" +
          window.location.hostname +
          ":" +
          window.location.port +
          "/cms_popups/" +
          cmsPopupId +
          "/user_cms_popups/add_click_count.json",
        type : 'PATCH',
        success: function(data) {
            // do nothing if success
            console.log(data);
        },
        error: function (error) {
            // log error to console
            console.log(error);
        },
    });
}

/* Events */

document.addEventListener("DOMContentLoaded", initPopupModal);
document.addEventListener("turbolinks:load", initPopupModal);