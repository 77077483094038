const initOrderProcessingForm = () => {
    if (!document.getElementById("order_processing")) return;

    const orderId = $("#order_processing").data('order-id');

    const pollOrderProcessing = async () => {

        const response = await fetch('/orders/' + orderId + '/processing.json', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        });
        
        const order = await response.json();

        if(order.payment_state == 'success' || order.payment_state == 'failed') {
            // when order processed reload page so rails redirect to correct page with flash messages
            location.reload()
        }
        else {
            // poll until waiting for order to be processed either success or fail
            setTimeout(pollOrderProcessing, 3000);
        }
    }

    pollOrderProcessing();
}

document.addEventListener('DOMContentLoaded', initOrderProcessingForm);