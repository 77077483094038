// Initialize select component
var initSelect = function () {
  var elems = document.querySelectorAll('select');
  var instances = M.FormSelect.init(elems, { classes: 'form-control' });

  // Custom handle select invalid on change_info form
  $('label.change-info__over-title__error').each(function () {
    $(this).siblings('.select-wrapper.form-control').addClass('invalid')
  })
};

document.addEventListener('DOMContentLoaded', initSelect);
document.addEventListener('turbolinks:load', initSelect);

// Inspired by https://stackoverflow.com/questions/52850091/materialize-select-and-dropdown-touch-event-selecting-wrong-item
document.addEventListener('click', function (e) {
  document
    .querySelectorAll("li[id^='select-options']")
    .forEach(function (selectOption, index) {
      selectOption.addEventListener('touchend', function (e) {
        e.stopPropagation();
      });
    });
});